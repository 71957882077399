import { PermissionEntityModel, PermissionQueryModel } from '@common-src/entity-model/permission-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, download } from './request';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/permission`;
class PermissionService implements ICRUDQ<PermissionEntityModel, PermissionQueryModel> {
    async create(model: PermissionEntityModel):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new PermissionEntityModel().toModel(res);
    }

    async update(model: PermissionEntityModel):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: PermissionEntityModel):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: PermissionQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/tree/${query.group}`;
        // const params = Object.assign({ page, limit }, query?.toService());
        const res = await get(url);
        return _.map(res, item => item = new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
    }

    async getAllPermission(): Promise<any> {
        const url = `${URL_PATH}/permissions`;
        const res = await get(url);
        return _.map(res, item => new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
    }

    async getAllPermissionV2(): Promise<any> {
        const url = `${URL_PATH}/v2/permissions`;
        const res = await get(url);
        return res;
    }

    async getAllPermissionGroup() {
        const url = `${URL_PATH}/group/all`;
        const res = await get(url);
        return res;
    }

    async getAllPermissionGroupV2() {
        const url = `${URL_PATH}/group/v2/all`;
        const res = await get(url);
        return res;
    }

    async getResources(modelId: string):Promise<Array<Object>> {
        const url = `${URL_PATH}/resource/getByPermissionId/${modelId}`;
        const res = await get(url);
        return res;
    }

    async getResourceTree(modelId: string):Promise<Array<Object>> {
        const url = `${URL_PATH}/resource/tree`;
        const res = await get(url);
        const permissions = _.map(res, item => new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
        const selectedPermissions = await this.getResources(modelId);
        if (selectedPermissions && selectedPermissions.length > 0) {
            _.forEach(permissions, item => {
                if (item.setSelect) {
                    item.setSelect(selectedPermissions, 'resourceCode');
                }
            });
        }
        return permissions;
    }

    async resourceBind(permissionId: string, resources: Array<any>):Promise<any> {
        const url = `${URL_PATH}/resource/bind`;
        const res = await post(url, { permissionId, resources });
        return res;
    }

    async importPermission(groupId: string, file: any):Promise<Array<Object>> {
        const url = `${URL_PATH}/import/${groupId}`;
        const formData = new FormData();
        formData.append('file', _.get(file, '[0].file'));
        if (groupId) {
            formData.append('groupId', groupId);
        }
        const res = await post(url, formData, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 });
        return _.get(res, 'resources');
    }

    async exportPermission(groupId: string):Promise<any> {
        const url = `${URL_PATH}/export/${groupId}`;
        const res = await post(url);
        return res;
    }
}

export default new PermissionService();
